.QuizChooseItem {
  position: relative;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.wrapperMobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
