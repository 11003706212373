.enterWordQuiz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.question {
  width: 100%;
  margin-bottom: 16px;
  color: #222826;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  white-space: break-spaces;
}

.img {
  width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
}

.mobileInput {
  height: 50vh;
  width: 100%;
}
