.fillWordQuiz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  padding-top: 5px;
  overflow-y: auto;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  overflow: hidden;
}

.gridRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gridCell {
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  width: 40px;
  height: 48px;
  color: #068585;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  background-color: #faf6ef;
  cursor: pointer;

  &:not(&.crossedCell):hover {
    color: #0a525c;
    background-color: #f3ecde;
  }
}

.hoveredCell {
  color: #0a525c;
  background-color: #f3ecde;
}

.crossedCell {
  position: relative;
  cursor: auto !important;
  color: #b3b7b5 !important;
  background-color: #f3f3f3 !important;
  border-radius: 4px !important;

  &::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    background-color: #000000;
  }
}

.words {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.word {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #b3b7b5;
}

.selectedWord {
  color: #068585;
}

.gridCellMobile {
  width: 27px;
  height: 40px;

  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-bottom: none;

  color: #068585;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  background-color: #faf6ef;
  cursor: pointer;

  &:not(&.crossedCell):hover {
    color: #0a525c;
    background-color: #f3ecde;
  }
}

.gridMobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  width: 100%;
}

.gridRowMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
