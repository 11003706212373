.itemWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.itemName {
  flex-grow: 1;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #222826;
  display: flex;
  align-items: flex-end;
}

.item {
  position: relative;
  width: 100%;
  max-width: 230px;
  max-height: 230px;
  border-radius: 12px;
  border: 2px solid #f3ecde;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  &:hover {
    &:not(.active, .error, .psevdoOver) {
      border-color: #fdd33a;
    }
  }

  &.active {
    border-width: 4px;
    border-color: #fdd33a;
  }

  &.success {
    border-color: #83b59a;
    pointer-events: none;
  }

  &.error {
    border-color: #f1615b;
  }
}

.psevdoOver {
  cursor: pointer;
}
.image {
  min-width: 230px;
  min-height: 230px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.stringItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #f3ecde;
  cursor: pointer;

  &:hover {
    &:not(.active, .error) {
      border-color: #fef2c4;
    }
  }

  &.active {
    border-width: 4px;
    border-color: #fdd33a;
  }

  &.success {
    border-color: #b4d5a8 !important;
    background-color: #b4d5a8;
    pointer-events: none;
  }

  &.error {
    border: 2px solid #f1615b;
  }
}

.itemNameMobile {
  font-size: 14px;
  margin-bottom: 4px;
  font-family: "Cera";
  line-height: 18px;
  flex-grow: 1;
  font-weight: 500;
  line-height: 23px;
  color: #222826;
  display: flex;
  align-items: flex-end;
}

.imageMobile {
  min-width: 151px;
  min-height: 151px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stringItemMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  padding: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #f3ecde;
  cursor: pointer;

  &:hover {
    &:not(.active, .error) {
      border-color: #fef2c4;
    }
  }

  &.active {
    border-width: 4px;
    border-color: #fdd33a;
  }

  &.success {
    border-color: #b4d5a8 !important;
    background-color: #b4d5a8;
    pointer-events: none;
  }

  &.error {
    border: 2px solid #f1615b;
  }
}
