.selectItem {
  position: relative;
  max-width: 234px;
  height: 48px;
  width: 100%;
  padding: 12px;
  font: 700 20px "Cera";
  text-align: center;
  border: 2px solid #b4d5a8;
  border-radius: 8px;
  cursor: pointer;
  background: none;
  color: #222826 !important;
}

.selectItem.selected {
  box-shadow: none;
  background-color: #b4d5a8;
}

.selectItem.error {
  border: 2px solid #f1615b;
  background: #f9e2ca;
}

@media (max-width: 1024px) {
  .selectItem {
    height: 40px;
    font-size: 18px;
    font-weight: 500;
    padding: 0;
  }
}
