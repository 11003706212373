.quizResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 32px;
}

.wrongTextWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
  background-color: #f9e2ca;
}

.wrongText {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #f1615b;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  background-color: #f1615b;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .iconWrapper {
    transform: scale(0.7);
  }
  .wrongTextWrapper {
    gap: 2px;
    padding: 16px;
    padding-left: 8px;
    height: 64px;
    justify-content: center;
  }
  .wrongText {
    font-size: 18px;
    line-height: 22px;
  }
  .quizResult {
    margin-top: 24px;
  }
}
