.total {
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  color: #7e7e7e;
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.list {
  list-style: none;
  padding: 0;

  li {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
