.cellWrapper {
  display: flex;
  width: 100%;
  font-size: 100%;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.5%;
  height: 12.5%;
  margin-left: 50%;
  font-size: 140%;
  font-weight: 800;
  text-align: center;
  background: #ffffff;
  border: 1px rgba(243, 236, 222, 1) solid;
  border-radius: 4px;
  color: rgb(6, 133, 133);

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
