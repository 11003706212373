.appInput {
  width: 100%;
  max-width: 318px;
  padding: 8px;
  font-size: 17px;
  line-height: 23px;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  border: 2px solid #e9e9e9;
  color: #222826;
}

.error {
  border-color: #f1615b;
}
