.rowLetters {
  display: flex;
  gap: 8px;
  width: 100%;
}

.rowError {
  background-color: #f5b7b7;
}

@media (max-width: 1024px) {
  .rowLetters {
    gap: 6px;
  }
}
