.quizTest {
  position: relative;
  display: flex;
  flex-direction: column;
}

.questionsWr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.itemWr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.buttonWr {
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
  width: 100%;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 12px;
}
