.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: fit-content;
}
.containerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  position: sticky;
}
.taskWrapper {
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: #faf6ef;
}

.task {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.dropZoneContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.dropZone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
}

.rootImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.dropPoint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.draggableItem {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.image {
  touch-action: none;
  width: 100%;
  height: auto;
  object-fit: cover;
  pointer-events: auto;
}
