.QuizChooseItem {
  position: relative;
}
.wrapper {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.rebus {
  max-width: 300px;
  margin: 0 auto 16px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.wrapperMobile {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
