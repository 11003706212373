.card {
  box-sizing: border-box;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  height: 60px;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  border: 2px solid rgba(243, 236, 222, 1);
  cursor: pointer;
  &:hover {
    border: 2px solid rgba(253, 211, 58, 1);
  }
}

.error {
  border: 2px solid rgba(241, 97, 91, 1);
  background-color: rgba(249, 226, 202, 1);
}
.success {
  border: 2px solid rgba(180, 213, 168, 1);
  &:hover {
    border: 2px solid rgba(180, 213, 168, 1);
  }
}

@media (max-width: 1024px) {
  .card {
    touch-action: none;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }
}
