.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  position: relative;
}

.imageWrapper {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 12px;
  border: 2px solid #f3ecde;
  cursor: pointer;
  overflow: hidden;

  &.success {
    border: 4px solid #83b59a;
  }

  &.error {
    border: 4px solid #f1615b;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 150px;
  overflow: hidden;
}

.text {
  width: 100%;
  padding: 12px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #b4d5a8;
  cursor: pointer;

  &.success {
    background-color: #b4d5a8;
  }

  &.error {
    border: 2px solid #f1615b;
  }
  &.activated {
    border: 4px solid #fdd33a;
  }
}

.activated {
  border: 4px solid #fdd33a;
}

.imageWrapperMobile {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 12px;
  border: 2px solid #f3ecde;
  cursor: pointer;
  overflow: hidden;

  &.success {
    border: 4px solid #83b59a;
  }

  &.error {
    border: 4px solid #f1615b;
  }

  &.activated {
    border: 4px solid #fdd33a;
  }
}
.textWrapperMobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 120px;
  overflow: hidden;
}

.textMobile {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #b4d5a8;
  cursor: pointer;

  &.success {
    background-color: #b4d5a8;
  }

  &.error {
    border: 2px solid #f1615b;
  }
  &.activated {
    border: 4px solid #fdd33a;
  }
}

.sameHeight {
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sameHeightMobile {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
