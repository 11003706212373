.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.dropZoneWrapper {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropZoneContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  padding-left: 10px;

  &::after {
    content: "";
    position: absolute;
    right: 215px;
    width: 8px;
    height: 100%;
    border-radius: 8px;
    background-color: #b4d5a8;
  }
}

.arrow {
  position: absolute;
  right: 202px;
  bottom: 0;
  width: 34px;
  height: 20px;
  background-repeat: no-repeat;
}

.dropZone {
  position: relative;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #faf6ef;
  width: 200px;
  height: 229px;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    border: 4px solid transparent;
    box-sizing: border-box;
    border-radius: 8px;
    pointer-events: none;
  }
}

.error {
  border: 4px solid #f1615b;
}
.success {
  border: 4px solid #83b59a;
}

.dropPoint {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  &.success {
    .dropName {
      color: #83b59a;
    }

    .dropZone {
      &::after {
        border-color: #83b59a;
      }
    }
  }

  &.error {
    .dropName {
      color: #f1615b;
    }

    .dropZone {
      &::after {
        border-color: #f1615b;
      }
    }
  }
}

.dropName {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    right: 1px;
    z-index: 10;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 4px solid #fdd33a;
  }
}

.draggableItems {
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.draggableItem {
  touch-action: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 229px;
  padding: 12px;
  border: 2px solid #f3ecde;
  border-radius: 8px;
  z-index: 999;
  &[data-img="noImage"] {
    justify-content: center;
  }
}

.draggableItemTitle {
  user-select: none;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  &[data-img="noImage"] {
    font-size: 24px;
  }
}

.draggableItemImage {
  user-select: none;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  pointer-events: none;
}
////////////////////////////////////////////////////////////////////////////////////////////////
.containerMobile {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 312px;
}

.draggableItemImageMobile {
  user-select: none;
  object-fit: cover;
  border-radius: 8px;
  width: 110px;
  height: 110px;
  pointer-events: none;
}
.draggableItemTitleMobile {
  user-select: none;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  &[data-img="noImage"] {
    font-size: 16px;
  }
}
.draggableItemMobile {
  touch-action: none;
  width: 124px;
  height: 202px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 2px solid #f3ecde;
  border-radius: 8px;
  z-index: 999;
  &[data-img="noImage"] {
    justify-content: center;
  }
}

.dropZoneContainerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  padding-left: 10px;

  &::after {
    content: "";
    position: absolute;
    right: 139px;
    width: 8px;
    height: 100%;
    border-radius: 8px;
    background-color: #b4d5a8;
  }
}
.arrowMobile {
  right: 126px;
  position: absolute;
  bottom: 0;
  width: 34px;
  height: 20px;
  background-repeat: no-repeat;
}

.dropZoneWrapperMobile {
  display: flex;
  gap: 0px;
  width: 100%;
}
.dropZoneMobile {
  height: 202px;
  width: 124px;
  position: relative;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #faf6ef;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    border: 4px solid transparent;
    box-sizing: border-box;
    border-radius: 8px;
    pointer-events: none;
  }
}
.dropNameMobile {
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  font-weight: 500;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    right: 1px;
    z-index: 10;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 4px solid #fdd33a;
  }
}
