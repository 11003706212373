@font-face {
    font-family: 'Cera';
    src: url('Cera-Regular-Web.eot');
    src: url('Cera-Regular-Web.eot?#iefix') format('embedded-opentype'),
    url('Cera-Regular-Web.woff2') format('woff2'),
    url('Cera-Regular-Web.woff') format('woff'),
    url('Cera-Regular-Web.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Cera';
    src: url('Cera-Medium-Web.eot');
    src: url('Cera-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('Cera-Medium-Web.woff2') format('woff2'),
    url('Cera-Medium-Web.woff') format('woff'),
    url('Cera-Medium-Web.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cera';
    src: url('Cera-Bold-Web.eot');
    src: url('Cera-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('Cera-Bold-Web.woff2') format('woff2'),
    url('Cera-Bold-Web.woff') format('woff'),
    url('Cera-Bold-Web.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}