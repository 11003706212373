.crosswordContainer svg g rect {
  rx: 0.8;
  cursor: pointer;
}
.crosswordContainer svg g text {
  font-size: 70%;
  font-weight: 800;
}

.crosswordContainer {
  font-size: 18px;
}

.crossword.grid input {
  margin-top: -2px !important;
}

.mobileInput {
  height: 50vh;
  width: 100%;
}
