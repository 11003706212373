.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  position: relative;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 200px;
  height: 150px;
  border-radius: 12px;
  border: 2px solid #f3ecde;
  cursor: pointer;
  overflow: hidden;

  &.success {
    border: 4px solid #83b59a;
  }

  &.error {
    border: 4px solid #f1615b;
  }
  &.activated {
    border: 4px solid #fdd33a;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageText {
  font-size: 24px;
}

.activated {
  border: 4px solid #fdd33a;
}

.imageWrapperMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 148px;
  height: 112px;
  border-radius: 12px;
  border: 2px solid #f3ecde;
  cursor: pointer;
  overflow: hidden;

  &.success {
    border: 4px solid #83b59a;
  }

  &.error {
    border: 4px solid #f1615b;
  }

  &.activated {
    border: 4px solid #fdd33a;
  }
}

.step {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
  color: #616c66;
  font-weight: 500;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 16px;
}
