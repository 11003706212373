.quizSuccess {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-height: 175px;
  padding-top: 8px;
}

.titleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fef2c4;
}

.message {
  color: #118860;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  background-color: #00a870;
  border-radius: 50%;
}

.successIconWrapper {
  margin-top: 36px;
}

.intersect {
  position: absolute;
  right: 5px;
  bottom: -17px;
  width: 18px;
  height: 17px;
  background-position: center;
}

@media (max-width: 1024px) {
  .titleWrapper {
    padding: 16px;
    width: 80%;
    gap: 8px;
    padding-left: 8px;
    z-index: 10;
    height: 78px;
  }
  .message {
    font-size: 18px;
  }
  .iconWrapper {
    transform: scale(0.7);
  }
  .successIconWrapper {
    margin-top: 40px;
    transform: scale(0.71);
    margin-right: -20px;
  }
}
