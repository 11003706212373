.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.dropZoneContainer {
  display: flex;
  width: fit-content;
  gap: 5px;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;

  &::after {
    content: "";
    height: 3px;
    background-color: #cbcbcb;
    position: absolute;
    width: 80%;
    top: 27px;
  }
}

.dropZone {
  border-radius: 8px;
  width: 55px;
  height: 55px;
  background-color: #faf6ef;
}

.dropPoint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  border-radius: 50%;
  border: 3px solid #cbcbcb;
  z-index: 10;
  background-color: white;
}

.filled {
  background-color: #efefef;
}

.draggableItems {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
}
@media (max-width: 560px) {
  .draggableItems {
    justify-content: space-between;
  }
}

.draggableItem {
  user-select: none;
  touch-action: none;
  pointer-events: none;
  width: 55px;
  height: 55px;
  padding: 5px;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  border: 2px solid #f3ecde;
  border-radius: 8px;
}

.clues {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
}

.draggableItemMobile {
  user-select: none;
  touch-action: none;
  pointer-events: none;
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  border: 2px solid #f3ecde;
  border-radius: 8px;
}

.cluesMobile {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}

.dropZoneMobile {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background-color: #faf6ef;
}

.dropNameMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  border-radius: 50%;
  border: 3px solid #cbcbcb;
  z-index: 10;
  background-color: white;
}

.dropZoneContainerMobile {
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;

  &::after {
    content: "";
    height: 3px;
    background-color: #cbcbcb;
    position: absolute;
    width: 100%;
    top: 20px;
  }
}
