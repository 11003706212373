.rebus {
  height: auto;
  width: 100%;
  max-width: 300px;
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
