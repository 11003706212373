.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.dropZoneContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  justify-content: space-around;
  position: relative;

  &::after {
    content: "";
    height: 3px;
    background-color: #cbcbcb;
    position: absolute;
    width: 80%;
    top: 27px;
  }
}

.dropZone {
  border-radius: 8px;
  background-color: #faf6ef;
  width: 100px;
  height: 50px;
}

.dropPoint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  border-radius: 50%;
  border: 3px solid #cbcbcb;
  z-index: 10;
  background-color: white;
}

.filled {
  background-color: #efefef;
}

.draggableItems {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.word {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100px;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #f3ecde;
  border-radius: 8px;
}

.wordMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid #f3ecde;
  border-radius: 8px;
}

.draggableItemMobile {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.dropZoneMobile {
  border-radius: 8px;
  width: 70px;
  height: 40px;
  background-color: #faf6ef;
}

.dropNameMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
  border-radius: 50%;
  border: 3px solid #cbcbcb;
  z-index: 10;
  background-color: white;
}

.dropZoneContainerMobile {
  display: flex;
  width: fit-content;
  gap: 5px;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;

  &::after {
    content: "";
    height: 3px;
    background-color: #cbcbcb;
    position: absolute;
    width: 80%;
    top: 20px;
  }
}
