@import "./shared/assets/fonts/fonts.css";

* {
  box-sizing: border-box;
}
html {
  -webkit-overflow-scrolling: touch !important;
}
body {
  margin: 0;
  padding: 40px;
  font-family:
    "Cera",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@media (max-width: 1024px) {
  body {
    padding: 36px 24px;
    -webkit-overflow-scrolling: touch !important;
  }
}

.screenHeight {
  /* max-height: var(--screenHeight) !important; */
  min-height: fit-content !important;
}
