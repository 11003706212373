.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.containerMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.wordTaskWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.text {
    color: #222826;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.wordsContainer {
    width: 100%;
    display: flex;
    gap: 8px;
}
.word {
    flex-grow: 1;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    border: 2px solid #f3ecde;
    color: #222826;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.active {
    opacity: 0.3;

    border: 4px solid #fdd33a;
}

.error {
    border: 4px solid rgba(241, 97, 91, 0.4);
    color: #f1615b !important;
}

.correct {
    color: #118860 !important;
    border: 4px solid #d8f0e3;
}

.activeWord {
    font-family: "Courier New", Courier, monospace;
    &_active {
        border: none !important;
        color: #068585;
        font-family: "Cera";
    }
}
