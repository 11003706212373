.jigsaw-puzzle__piece {
  border: 2px solid rgba(243, 236, 222, 1);
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
}

.jigsaw-puzzle__piece--solved {
  border: 2px solid rgba(180, 213, 168, 1) !important;
}
.jigsaw-puzzle__piece--dragging {
  border: 2px solid rgba(253, 211, 58, 1);
}
