.dropContainer {
  border-radius: 8px;
}

.isImage {
  width: 150px;
  height: 150px;
}

.isText {
  width: 225px;
  height: 56px;
}
