.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortableItems {
  display: flex;
  gap: 8px;
}

.error {
  border-color: red;
}

.answer {
  width: 100%;
  max-width: 150px;
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.success {
  border: 2px solid rgba(180, 213, 168, 1);
}
@media (max-width: 1024px) {
  .answer {
    max-width: 120px;
  }
}
