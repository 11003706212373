.quizWrapper {
  width: 100%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #ffffff;
  overflow: hidden;
}

.quizWrapper_block {
  display: block;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #dbeeee;
}

.title {
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #f1615b;
}

.subtitle {
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  background-color: #fef2c4;
  color: #222826;
  border-radius: 8px;
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
  color: #616c66;
  font-weight: 500;
  text-transform: uppercase;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.subtitleMobile {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  background-color: #fef2c4;
  color: #222826;
  border-radius: 8px;
}

.descriptionMobile {
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 18px;
  color: #616c66;
  font-weight: 500;
  text-transform: uppercase;
}

.iconWrapperMobile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #dbeeee;
  transform: scale(0.75);
  margin-left: -6px;
}

.titleWrapperMobile {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
}
