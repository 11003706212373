.letterSquare {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(10, 82, 92, 1);
  width: 100%;
  min-width: 27px;
  height: 48px;
  background-color: #f3ecde;
  cursor: pointer;
}
.crossed {
  color: rgba(179, 183, 181, 1);
  background-color: rgba(243, 243, 243, 1);
}

.lineThrough {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  border-bottom: 1px solid black;
}
.error {
  border: 2px solid rgba(241, 97, 91, 1);
  background-color: #f3ecde;
}

@media (max-width: 1024px) {
  .letterSquare {
    font-size: 16px;
    line-height: 20px;
  }
}
