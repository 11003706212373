.wrapper {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  height: auto;
}
.code {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.rowSibling {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 8px;
}
.cellSibling {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 43px;
  width: 100%;
  height: 54px;
  font: 700 20px "Cera";
  color: black;
  background-color: rgba(250, 250, 250, 1);
}

.td {
  text-align: center;
  min-width: 50px;
  font: 700 20px "Cera";
  color: rgba(6, 133, 133, 1);
  height: 54px;
  background-color: rgba(250, 246, 239, 1);
}
.codeTable {
  border-collapse: separate;
  border-spacing: 8px;
  margin-bottom: 30px;
}
.bodyTableCode {
  background-color: gray;
  border-spacing: 8px;
  :first-child {
    td {
      background-color: rgba(250, 250, 250, 1);
      color: black;
    }
  }
  :nth-child(n + 1) {
    :first-child {
      background-color: rgba(250, 250, 250, 1);
      color: black;
    }
  }
}
.inputWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 490px) {
  .wrapper {
    position: relative;
    padding-bottom: 52px;

    .inputWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.input {
  user-select: none;
  text-transform: uppercase;
  max-width: 43px;
  width: 100% !important;
  height: 52px;
  font: 700 20px "Cera";
  color: rgba(6, 133, 133, 1);
  text-align: center;
  padding: 0 5px;
  border: none;
  background-color: rgba(250, 246, 239, 1);
  &:focus {
    outline: none;
    background-color: white;
    border: 2px solid rgba(253, 211, 58, 1);
  }
}
.filled {
  background-color: white;
  border: 2px solid rgba(243, 236, 222, 1);
}

.error {
  background-color: rgba(249, 226, 202, 1) !important;
  border: 2px solid rgba(241, 97, 91, 1) !important;
  color: black;
}

.success {
  border: 2px solid rgba(180, 213, 168, 1) !important;
  color: black;
}
.mobileInput {
  padding-bottom: 500px;
}
.inputMobile {
  user-select: none;
  text-transform: uppercase;
  max-width: 30px;
  font-size: 16px;
  width: 100% !important;
  height: 52px;
  font: 700 20px "Cera";
  color: rgba(6, 133, 133, 1);
  text-align: center;
  padding: 0 5px;
  border: none;
  background-color: rgba(250, 246, 239, 1);
  &:focus {
    outline: none;
    background-color: white;
    border: 2px solid rgba(253, 211, 58, 1);
  }
}

.cellSiblingMobile {
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  font: 700 20px "Cera";
  color: black;
  background-color: rgba(250, 250, 250, 1);
}
#inputs div {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#inputs {
  width: 100%;
}
