.draggableItem {
  z-index: 1;
  cursor: move;
  touch-action: none;

  &:hover {
    opacity: 0.75;
  }
}

.animation {
  touch-action: none;
  transition: transform 0.5s ease 0s;
}

.image {
  touch-action: none;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;

  &:hover {
    opacity: inherit;
  }
}

.text {
  touch-action: none;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 8px 16px;
}
