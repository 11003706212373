.appButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
  font-family: "Cera";
  background-color: #00a870;
  border-radius: 35px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  color: #ffffff;

  &:hover {
    background-color: #0ac687;
  }

  &:disabled {
    background-color: #c6dbd4;
  }
}
