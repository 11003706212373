.QuizChooseItem {
  position: relative;
  width: 100%;
}
.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  // grid-template-rows: 80px 80px 80px;

  gap: 20px;
}
.sameHeight {
  grid-template-rows: 80px 80px 80px;
}

.rebus {
  position: relative;
  max-width: 300px;
  width: 100%;
  margin: 0 auto 16px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.wrapperMobile {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
